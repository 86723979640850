import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SocialMediaDiego from "../../components/socialMedia/SocialMediaDiego";
import SocialMediaAlfredo from "../../components/socialMedia/SocialMediaAlfredo";
import { Fade } from "react-reveal";
import "./ContactComponent.css";

function Contact(props) {
  const theme = props.theme;

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
      <Fade bottom duration={2000} distance="20px">
           <img
                className="logo-milktech"
                src={require(`../../assests/images/milktech.svg`)}
                alt=""
              />
          <div className="texto-milktech">
               We are a decentralized company, which together with the help of people from all over the world we give solutions, 
                 ideas and value proposals to companies and entrepreneurs.
              <br/><br/>
              Only people who love what they do work in this company.
          </div>
        </Fade>

        <Fade bottom duration={1000} distance="40px">
          <div className="fouders-milktech">Founders</div>

          <div className="contact-heading-div">
            <div className="contact-heading-text-div">
              <h1
                className="contact-heading-text"
                style={{ color: theme.text }}
              >
                Diego Gonzalez 
              </h1>
              <p
                className="contact-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                Backend developer with expericience in software development with apis and microservices. 
              </p>
              <SocialMediaDiego />
            </div>

            <div className="contact-heading-img-div">
              <img
                className="profile-pic"
                src={require(`../../assests/images/diego.png`)}
                alt=""
              />
            </div>
          </div>
        </Fade>
        <Fade bottom duration={1000} distance="40px">
          <div className="contact-heading-div">
            <div className="contact-heading-img-div">
              <img
                className="profile-pic"
                src={require(`../../assests/images/alfredo.png`)}
                alt=""
              />
            </div>
            <div className="contact-heading-text-div">
              <h1
                className="contact-heading-text"
                style={{ color: theme.text }}
              >
                Alfredo Cendejas
              </h1>
              <p
                className="contact-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                Software engineer curious about entrepreneurship <br/> 8 years managing international projects.
              </p>
              <SocialMediaAlfredo />
            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Contact;
