/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "G-3CSKRRD5N2",
};

//Home Page
const greeting = {
  title: "we're the solution",
  title2: "Greystone",
  logo_name: "Greystone",
  // nickname: "harry / picleric",
  full_name: "Greystone",
  subTitle:
    "a decentralized software company",
  resumeLink:"/services%20brochure.pdf",
  mail: "mailto:info@greystonestrategics.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  linkedin: "https://www.linkedin.com/company/greystonestrategics/",
  gmail: "info@greystonestrategics.com",
  // gitlab: "https://gitlab.com/harikanani",
   //facebook: "https://www.facebook.com/milkt3ch",
   //instagram: "https://www.instagram.com/greystonestrategics.com/",
};

const skills = {
  data: [

    {
      title: "Enterprise Blockchain",
      fileName: "BlockchainImg",
      skills: [
        "♥ Traceability in real time",
        "♥ Security in all your transactions",
        "♥ we work with multi-chains",
      ],
      softwareSkills: [
        {
          skillName: "ETH",
          fontAwesomeClassname: "simple-icons:ethereum",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Bitcoin",
          fontAwesomeClassname: "simple-icons:bitcoin",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Binance",
          fontAwesomeClassname: "simple-icons:binance",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Cardano",
          fontAwesomeClassname: "simple-icons:cardano",
          style: {
            color: "#00C2FF",
          },
        },
        
      ],
    },
    {
      title: "Corporate & Interorganizational Software Systems",
      fileName: "FullStackImg",
      skills: [
        "♥ Customer Self-service Apps",
        "♥ Software for a Specific Business Function",
        "♥ Enterprise resource & process management",
        "♥ Cross-industry | Retail | MLM | Analytics | Financial management & accounting",
      ],
      softwareSkills: [
        {
          skillName: "Laravel",
          fontAwesomeClassname: "simple-icons:laravel",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "simple-icons:flask",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#00C2FF",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        // {
        //   skillName: "Android",
        //   fontAwesomeClassname: "simple-icons:android",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        {
          skillName: "GO",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#00C2FF",
          },
        },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        // {
        //   skillName: "Dart",
        //   fontAwesomeClassname: "simple-icons:dart",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        // {
        //   skillName: "Linux",
        //   fontAwesomeClassname: "simple-icons:linux",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#00C2FF",
          },
        },
        // {
        //   skillName: "jQuery",
        //   fontAwesomeClassname: "simple-icons:jquery",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
         {
           skillName: "Wordpress",
           fontAwesomeClassname: "simple-icons:wordpress",
           style: {
             color: "#00C2FF",
           },
         },
        // {
        //   skillName: "Apache",
        //   fontAwesomeClassname: "simple-icons:apache",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        // {
        //   skillName: "Git",
        //   fontAwesomeClassname: "simple-icons:git",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        // {
        //   skillName: "C",
        //   fontAwesomeClassname: "simple-icons:c",
        //   style: {
        //     color: "#00C2FF",
        //   },
        // },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#00C2FF",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "♥ Multiple cloud platforms support",
        "♥ Infrastructure as code, especially for companies",
        "♥ CI/CD | SRE | DEVOPS",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "simple-icons:jenkins",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "simple-icons:linux",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#00C2FF",
          },
        },
        {
          skillName: "Nginx",
          fontAwesomeClassname: "simple-icons:nginx",
          style: {
            color: "#00C2FF",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "SRM Institute of Science and Technology",
      subtitle: "Bachelors in Computer Science and Engineering",
      logo_path: "SRM_Logo.png",
      alt_name: "SSEC",
      duration: "2019 - 2023",
      descriptions: [
        "⚡  I'm currently pursuing my Bachelors in Computer Science and Engineering.",
        // "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "https://www.srmist.edu.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Google Cloud",
      subtitle: "Google Cloud Training",
      logo_path: "gcp_logo.png",
      
      alt_name: "GCP",
      // color_code: "#2AAFED",
      color_code: "#00C2FF",
    },
    {
      title: "Nginx",
      subtitle: "Web Services and Load Balancer",
      logo_path: "nginx.png",
      // certificate_link:
      //   "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "Nginx",
      color_code: "#00C2FF",
    },
    {
      title: "Kubernetes",
      subtitle: "Infrestructure as code (web)",
      logo_path: "kubernetes.png",
      // certificate_link:
      //   "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "Kubernetes",
      color_code: "#00C2FF",
    },
    // {
    //   title: "A300: Atlas Security",
    //   subtitle: "MongoDB University",
    //   logo_path: "mongo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
    //   alt_name: "MongoDB University",
    //   // color_code: "#00C2FF",
    //   color_code: "#00C2FF",
    // },
    {
      title: "Laravel",
      subtitle: "Laravel APIs WEB",
      logo_path: "laravel.png",
      alt_name: "Laravel",
      color_code: "#00C2FF",
    },
    // {
    //   title: "MLH Local Hack Day: Build",
    //   subtitle: "Major League Hacking",
    //   logo_path: "mlh-logo.svg",
    //   certificate_link:
    //     "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
    //   alt_name: "Google",
    //   color_code: "#00C2FF",
    // },
    {
      title: "React JS",
      subtitle: "React from web apps",
      logo_path: "react.png",
      alt_name: "Postman",
      // color_code: "#00C2FF",
      color_code: "#00C2FF",
    },
    {
      title: "Nginx",
      subtitle: "New on web to producction",
      logo_path: "nginx.png",
      alt_name: "Nginx",
      color_code: "#00C2FF",
    },
    {
      title: "React Native",
      subtitle: "Apps Mobile just one code",
      logo_path: "react.png",
      alt_name: "React",
      color_code: "#00C2FF",
    },
    {
      title: "Scrum",
      subtitle: "Scrum for Everyone",
      logo_path: "scrum.png",
      alt_name: "Scrum",
      // color_code: "#00C2FF",
      color_code: "#00C2FF",
    },
    {
      title: "GO",
      subtitle: "Microservices",
      logo_path: "go.png",
      alt_name: "Go",
      color_code: "#00C2FF",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed one internship. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "Technocolabs",
          company_url: "https://technocolabs.com/",
          logo_path: "Technocolabs.jfif",
          duration: "Sept 2020 - Oct 2020",
          location: "Remote",
          description: `Analysed the changes before and after people started using sanitizers using the covid-19 dataset. Built and analysed several predictive models for defaulting user’s credit card and deployed it on a full-stack website using Flask. Made a predictive model for Blood Donation for Future Expectancy and deployed it on a fully-fledged website. Increased productivity and problem-solving technics by 20%. 
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Data Science Intern",
          company: "The Sparks Foundation",
          company_url: "hhttps://thesparksfoundation.info/",
          logo_path: "TSF.png",
          duration: "Aug 2020 - Sept 2020",
          location: "Remote",
          description: `Developed predictive models and deployed it on a full-stack website using Flask. Used Boxplot, Heatmap and Confusion Matrix for data visualization. Deep analysis on the superstore dataset using Bar Plots, Bar Charts, Pair Plots and Joint Plots for specific regions.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "R&D Member",
          company: "Webarch Club",
          company_url: "https://www.webarchsrm.com/",
          logo_path: "Webarch.jfif",
          duration: "Mar 2020 - Nov 2020",
          location: "SRM IST Kattankulathur",
          description:
            "Member responsibilities were to help students in project planning, review issues and pull requests, ensure smooth progress and help juniors out in their difficulties.",
          color: "#4285F4",
        },
        {
          title: "Campus Leader",
          company: "Code For Cause",
          company_url: "https://www.codecau.se/",
          logo_path: "CFC.jfif",
          duration: "Nov 2020 - Present",
          location: "SRM IST Kattankulathur",
          description:
            "Held numerous workshops and distributed learning content for freshers. Contributed to development of the causefolio website displaying the information about the Code for Cause Community.",
          color: "#a60000",
        },
        {
          title: "Machine Learning - Research associate",
          company: "Next Tech Lab",
          company_url: "https://nextech.io/",
          logo_path: "NTL.jfif",
          duration: "Oct 2020 - Present",
          location: "SRM IST Kattankulathur",
          description:
            "Associate responsibilities were to build fast and efficient machine learning models, have talks on research topics related to computer vision and deep learning. Build team projects and intract with fellow members and ensure smooth progress and help juniors out in their difficulties.",
          color: "#62cff0",
        },
        {
          title: "Campus Ambassador",
          company: "Bagcampus",
          company_url: "https://bagcampus.in/",
          logo_path: "Bagcampus.jpg",
          duration: "Dec 2020 - May 2021",
          location: "Remote",
          description:
            "Spread Awareness of new Technologies and Companies and new Opportunities to Students and Grow Bagcampus Community.",
          color: "#0059c9",
        },
        {
          title: "Volunteer",
          company: "Google Crowdsource",
          company_url: "https://indiaenvironment.org/",
          logo_path: "GCS.jfif",
          duration: "Sept 2020 - Present",
          location: "Remote",
          description:
            "As a member of the global Crowdsource community one can gain recognition from other Crowdsourcers and Google.",
          color: "#ffffff",
        },
        {
          title: "Student Volunteer",
          company: "National Service Scheme",
          company_url: "https://indiaenvironment.org/",
          logo_path: "NSS.jfif",
          duration: "Dec 2019 - May 2020",
          location: "SRM IST Kattankulathur",
          description:
            "Take time out over weekends to join a gang of nation builders, nature enthusiasts to conserve what is left.",
          color: "#282b6c",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "We currently have projects in various parts of the world and we are always looking for talent, either by project or as an intern",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Us",
    profile_image_path: "profile_photo_2.jpeg",
    description:
      "You can Contact Us at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others.",
    link: "https://medium.com/@vedanshvijay/",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Internship Program",
      /*url: "https://www.mindspace.cf/",*/
      description:
        "Perform professional practices from home, learn an agile methodology and improve in your area. Backend and Frontend",
      languages: [
        {
          name: "React",
          iconifyClass: "la:react",
        },
        {
          name: "GIT",
          iconifyClass: "bx:bx-git-branch",
        },
        {
          name: "Laravel",
          iconifyClass: "fa-brands:laravel",
        },
        {
          name: "Python",
          iconifyClass: "akar-icons:python-fill",
        },
        {
          name: "VSCODE",
          iconifyClass: "akar-icons:vscode-fill",
        },
      ],
    },
    {
      id: "1",
      name: "Regional Partner",
      /*url: "https://github.com/vvHacker007/Youtube-Downloader/",*/
      description:
        "Do you love software development? Start your own company leveraged from developers from around the world.",
      languages: [
        {
          name: "Partner",
          iconifyClass: "carbon:partnership",
        },
        {
          name: "Wold",
          iconifyClass: "pepicons:internet",
        },
        
      ],
    },
    {
      id: "2",
      name: "Scrum for everyone",
      /*url: "https://github.com/vvHacker007/Tune-In",*/
      description:
        "Our scrum campaign teaches the methodology for free to organizations that want to implement agile development",
      languages: [
        {
          name: "BitBucket",
          iconifyClass: "cib:bitbucket",
        },
        {
          name: "Jira",
          iconifyClass: "mdi:jira",
        },
        {
          name: "Teams",
          iconifyClass: "bx:bxl-microsoft-teams",
        },
        {
          name: "GIT",
          iconifyClass: "ion:git-merge",
        },
      ],
    },
    /*{
      id: "3",
      name: "Event Scheduler",
      /*url: "https://github.com/vvHacker007/Event-Scheduler",
      description:
        "It is an all-purpose scheduler software. It is designed to monitor all the activities and give appropriate reminders for them.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Flask",
          iconifyClass: "cib-flask",
        },
      ],
    },
    {
    /*{
      id: "4",
      name: "Movie Reccomendation System",
      /*url: "https://github.com/vvHacker007/Movie-Recommendation-Systems",
      description:
        "Made three python kernels explaining and implementing the three different types of movie recommender systems.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "3",
      name: "Login Authenticator",
      /*url: "https://github.com/vvHacker007/Login-Authenticator",
      description: "Made a template for a Login Authenticator using Flask ",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Flask",
          iconifyClass: "cib-flask",
        },
      ],
    },
    {
      id: "4",
      name: "Calculator",
      /*url: "https://github.com/vvHacker007/Calculator",
      description:
        "Made a GUI based Calculator. Also made an executable file for the project which can be downloaded and used by anyone on their personal computer.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "5",
      name: "Notepad",
      /*url: "https://github.com/vvHacker007/Notepad",
      description:
        "Made a GUI based Notepad. Also made an executable file for the project which can be downloaded and used by anyone on their personal computer.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "6",
      name: "Tetris",
      /*url: "https://github.com/vvHacker007/Tetris",
      description: "Made a GUI based arcade game Tetris using pygame.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "7",
      name: "Steganography",
      /*url: "https://github.com/vvHacker007/Steganography",
      description:
        "A CLI tool to conceal messages within a file, text, image, or video.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "OpenCV",
          iconifyClass: "logos-opencv",
        },
      ],
    },
    {
      id: "8",
      name: "Mini Paint",
      /*url: "https://github.com/vvHacker007/Mini-Paint",
      description: "GUI based Mini Paint Application.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "9",
      name: "Speedtest",
      /*url: "https://github.com/vvHacker007/Speed-test",
      description:
        "An internet speed testing application which can notify about the download speed, upload speed, and also the reaction time of the connection.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "10",
      name: "Cat Classifier",
      /*url: "https://github.com/vvHacker007/Cat-Classifier",
      description:
        "Binary Cat Classifier made by hard coding Neural Networks in Python without using libraries such as Tensorflow or keras.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "11",
      name: "Snake",
      /*url: "https://github.com/vvHacker007/Snake",
      description:
        "A snake game which covers all the basic features like score, high score and an automated speed increase simultaneously when the snake eats the food.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "12",
      name: "Score Predictor",
      /*url: "https://score-predictor-app.herokuapp.com/",
      description:
        "Predicts students score based on the number of hours the student puts in it.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Flask",
          iconifyClass: "cib-flask",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "13",
      name: "Blood Donation Predictor",
      /*url: "https://blood-donation-predictor-app.herokuapp.com/",
      description:
        "Predicts if one is ready to donate blood based on the user's blood donation history.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "14",
      name: "Credit-Card Default Predictor",
      /*url: "https://default-predictor-app.herokuapp.com/",
      description:
        "Predicts whether user's credit card would be defaulted or not based on the user's bank statements and history.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "15",
      name: "Portfolio Website",
      /*url: "https://vvhacker007.github.io/Portfolio/",
      description: "You are stalking it right now 😉",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
    },*/
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
