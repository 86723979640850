import React from "react";
import "./SocialMedia.css";

export default function socialMedia() {
  return (
    <div className="social-media-div">
    
       <a
        href="https://www.facebook.com/alfredocendejas.it"
        className="icon-button facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-facebook"></i>
        <span></span>
      </a>
      <a
        href="https://www.linkedin.com/in/isc-agc/"
        className="icon-button linkedin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-linkedin-in"></i>
        <span></span>
      </a>
      <a
        href="mailto:agutierrez@greystonestrategics.com"
        className="icon-button google"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="far fa-envelope"></i>
        <span></span>
      </a>
      <a
        href="https://twitter.com/alfredcendejas"
        className="icon-button twitter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-twitter"></i>
        <span></span>
      </a>
      <a
        href="https://www.instagram.com/_alfredocendejas/"
        className="icon-button instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-instagram"></i>
        <span></span>
      </a>
    </div>
  );
}
