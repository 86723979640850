import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";

import { Link } from "react-router-dom";
import SocialMedia from "../../components/socialMedia/SocialMedia";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    
    <div className="footer-div">
      
      
      <Fade>
          <footer>
            <div className="footer-content-wrapper">
            
            <div className="footer-col large-25 small-50 tiny-100 ta-l flt">
            <svg width="90%" viewBox="0 0 1446 335" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M161 0C177.718 0.718 187.052 9.385 189 26C185.947 45.691 174.613 53.858 155 50.5C140.853 44.039 135.353 33.206 138.5 18C142.394 7.324 149.894 1.324 161 0Z" fill="#00C2FF"/>
            <path opacity="0.981" fill-rule="evenodd" clip-rule="evenodd" d="M79 43C126.528 42.496 157.362 64.83 171.5 110C175.018 125.831 174.351 141.498 169.5 157C167.554 163.558 164.554 169.558 160.5 175C147.756 160.761 132.256 150.761 114 145C122.202 124.9 116.535 110.066 97 100.5C76.77 96.091 63.27 103.591 56.5 123C54.257 142.355 62.423 154.855 81 160.5C121.234 160.761 150.068 178.927 167.5 215C182.043 260.711 169.543 296.877 130 323.5C95.712 340.254 63.046 337.587 32 315.5C11.225 297.448 0.559 274.614 0 247C18.667 247 37.333 247 56 247C58.195 268.694 70.195 279.194 92 278.5C112.552 272.572 120.718 259.072 116.5 238C112.152 227.65 104.652 220.817 94 217.5C53.203 217.653 24.036 199.486 6.5 163C-7.443 120.91 2.724 86.077 37 58.5C49.938 50.186 63.938 45.02 79 43Z" fill="#00C2FF"/>
            <path opacity="0.941" fill-rule="evenodd" clip-rule="evenodd" d="M935.999 89C943.999 89 951.999 89 959.999 89C959.999 98 959.999 107 959.999 116C971.669 116 983.329 116 994.999 116C994.999 122.667 994.999 129.333 994.999 136C983.329 136 971.669 136 959.999 136C959.529 161.398 960.029 186.731 961.499 212C964.949 219.317 970.789 222.983 978.999 223C985.189 222.403 990.849 220.403 995.999 217C998.949 222.353 1001.62 227.853 1004 233.5C995.639 239.805 986.139 242.971 975.499 243C952.599 242.754 939.599 231.421 936.499 209C935.999 169.001 935.829 129.001 935.999 89Z" fill="#00C2FF"/>
            <path opacity="0.961" fill-rule="evenodd" clip-rule="evenodd" d="M309 115C324.85 113.797 339.517 117.297 353 125.5C356.403 127.781 359.237 130.614 361.5 134C361.999 128.009 362.166 122.009 362 116C369.333 116 376.667 116 384 116C384.167 157.001 384 198.001 383.5 239C380.628 263.866 367.128 279.366 343 285.5C320.857 289.958 299.19 288.291 278 280.5C271.586 277.88 265.753 274.38 260.5 270C260.532 267.902 261.199 265.902 262.5 264C265.253 259.492 268.253 255.159 271.5 251C292.536 266.757 315.703 270.923 341 263.5C353.06 257.424 359.56 247.59 360.5 234C361.167 229 361.167 224 360.5 219C345.372 232.912 327.539 238.412 307 235.5C281.032 231.855 263.865 217.688 255.5 193C248.382 149.652 266.215 123.652 309 115ZM311 136C327.757 133.973 341.924 138.973 353.5 151C362.692 165.931 363.358 181.265 355.5 197C344.015 212.056 328.848 217.889 310 214.5C286.905 208.725 276.072 193.891 277.5 170C281.004 151.018 292.171 139.685 311 136Z" fill="#00C2FF"/>
            <path opacity="0.945" fill-rule="evenodd" clip-rule="evenodd" d="M485 115C488 115 491 115 494 115C494 122.667 494 130.333 494 138C470.811 135.723 456.311 145.723 450.5 168C450 192.331 449.833 216.664 450 241C442 241 434 241 426 241C426 199.333 426 157.667 426 116C433.667 116 441.333 116 449 116C448.834 122.009 449.001 128.009 449.5 134C454.731 126.792 461.564 121.625 470 118.5C475.096 117.14 480.096 115.974 485 115Z" fill="#00C2FF"/>
            <path opacity="0.954" fill-rule="evenodd" clip-rule="evenodd" d="M563 115C594.521 112.99 616.354 126.323 628.5 155C630.552 162.197 631.719 169.53 632 177C599.169 183.033 566.502 189.699 534 197C544.563 216.282 560.897 224.449 583 221.5C593.819 220.258 602.819 215.591 610 207.5C610.667 206.833 611.333 206.833 612 207.5C616.189 212.188 620.189 217.021 624 222C616.071 231.467 606.071 237.634 594 240.5C570.803 245.929 549.469 241.929 530 228.5C509.582 209.009 503.082 185.509 510.5 158C519.292 132.875 536.792 118.542 563 115ZM565 135C587.294 133.954 601.627 143.954 608 165C582 169.667 556 174.333 530 179C530.001 163.502 536.001 151.002 548 141.5C553.388 138.252 559.054 136.085 565 135Z" fill="#00C2FF"/>
            <path opacity="0.955" fill-rule="evenodd" clip-rule="evenodd" d="M853 115C871.285 113.957 888.618 117.457 905 125.5C906.02 126.549 906.18 127.716 905.5 129C902.9 134.747 899.73 140.081 896 145C878.874 135.276 860.874 133.109 842 138.5C833.255 142.485 830.422 148.985 833.5 158C835.764 160.933 838.598 163.1 842 164.5C855.182 168.137 868.516 171.137 882 173.5C906.82 179.959 915.32 195.459 907.5 220C901.33 230.013 892.5 236.513 881 239.5C856.868 245.155 833.535 242.821 811 232.5C808.688 231.186 806.688 229.519 805 227.5C807.776 221.114 810.943 214.948 814.5 209C834.559 221.982 856.059 225.149 879 218.5C888.16 212.215 889.16 204.882 882 196.5C870.129 191.725 857.796 188.725 845 187.5C810.978 181.617 801.145 163.117 815.5 132C825.902 121.593 838.402 115.926 853 115Z" fill="#00C2FF"/>
            <path opacity="0.957" fill-rule="evenodd" clip-rule="evenodd" d="M1072 115C1100.96 113.059 1122.79 124.392 1137.5 149C1148.24 174.564 1145.57 198.564 1129.5 221C1112.05 238.995 1090.88 245.828 1066 241.5C1032.65 233.846 1015.32 212.846 1014 178.5C1016.49 141.843 1035.83 120.677 1072 115ZM1072 136C1089.45 133.974 1103.28 139.974 1113.5 154C1122.96 172.647 1121.96 190.647 1110.5 208C1094.21 223.541 1076.05 226.041 1056 215.5C1041.35 203.89 1035.51 188.723 1038.5 170C1042.55 151.447 1053.72 140.114 1072 136Z" fill="#00C2FF"/>
            <path opacity="0.97" fill-rule="evenodd" clip-rule="evenodd" d="M1233 115C1252.63 112.816 1269.13 118.816 1282.5 133C1287.07 140.028 1290.07 147.695 1291.5 156C1292 184.331 1292.17 212.665 1292 241C1284.33 241 1276.67 241 1269 241C1269.46 213.624 1268.96 186.291 1267.5 159C1262.88 143.577 1252.38 135.91 1236 136C1212.2 136.971 1199.36 149.304 1197.5 173C1197 195.664 1196.83 218.331 1197 241C1189.33 241 1181.67 241 1174 241C1174 199.333 1174 157.667 1174 116C1181.33 116 1188.67 116 1196 116C1195.83 122.009 1196 128.009 1196.5 134C1200.41 128.789 1205.25 124.623 1211 121.5C1218.19 118.377 1225.52 116.21 1233 115Z" fill="#00C2FF"/>
            <path opacity="0.954" fill-rule="evenodd" clip-rule="evenodd" d="M1378 115C1417.33 113.675 1439.99 132.342 1446 171C1445.91 173.464 1445.24 175.63 1444 177.5C1413.02 183.608 1382.02 189.608 1351 195.5C1350.07 195.888 1349.41 196.555 1349 197.5C1355.85 211.659 1367.18 219.659 1383 221.5C1399.33 223.613 1413.33 218.947 1425 207.5C1425.67 206.833 1426.33 206.833 1427 207.5C1431 212.5 1435 217.5 1439 222.5C1421.09 239.91 1399.76 246.243 1375 241.5C1338.72 233.204 1321.22 210.371 1322.5 173C1326.27 139.402 1344.77 120.069 1378 115ZM1380 135C1401.67 134.032 1416 143.698 1423 164C1397.1 169.513 1371.1 174.513 1345 179C1344.96 154.73 1356.63 140.063 1380 135Z" fill="#00C2FF"/>
            <path opacity="0.957" fill-rule="evenodd" clip-rule="evenodd" d="M661 116C668.667 116 676.333 116 684 116C683.833 138.003 684 160.003 684.5 182C687.546 208.795 702.379 219.295 729 213.5C743.694 208.661 752.194 198.494 754.5 183C755 160.669 755.167 138.336 755 116C763 116 771 116 779 116C779.167 154.001 779 192.001 778.5 230C776.692 268.808 756.359 288.141 717.5 288C698.557 288.224 681.057 283.39 665 273.5C661.092 271.452 660.259 268.619 662.5 265C665.862 260.47 669.029 255.803 672 251C693.44 268.185 716.773 271.351 742 260.5C753.176 249.297 757.343 235.797 754.5 220C740.321 233.425 723.488 238.592 704 235.5C678.713 231.88 664.547 217.38 661.5 192C661 166.669 660.833 141.336 661 116Z" fill="#00C2FF"/>
            </svg>


              <h3><Link to="termsandconditions"> Terms & Conditions</Link></h3>
              <h3><Link to="privacypolicy"> Privacy Policy</Link></h3> 
              </div>
              <div className="footer-col large-25 small-50 tiny-100 ta-l flt">
              
              </div><div className="footer-col large-25 small-50 tiny-100 ta-l flt">
              </div><div className="large-25 small-50 tiny-100 ta-l flt">
              <h3>
        
                <a href="mailto:info@greystonestrategics.com">info@greystonestrategics.com</a></h3>
              <h3><a  href="tel:+18005748257">+1 (800) 574-8257</a> </h3><br/>
              <SocialMedia />
              </div>
            </div>
            <div className="clearfix"></div>
          </footer>
       

      </Fade>
    </div>
  );
}
